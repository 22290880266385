@use "sass:meta" as ---zyywjvlcacm;.scrolled .hamburger-inner,
.scrolled .hamburger-inner::before,
.scrolled .hamburger-inner::after {
    background: $hamburger-layer-scrolled-color;
}

.scrolled .hamburger.is-active .hamburger-inner,
.scrolled .hamburger.is-active .hamburger-inner::before,
.scrolled .hamburger.is-active .hamburger-inner::after {
    background: $hamburger-layer-scrolled-color;
}

;@include ---zyywjvlcacm.load-css("sass-embedded-legacy-load-done:12096");