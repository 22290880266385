@use "sass:meta" as ---zyywjvlcacm;// Common
.header-top-logo-centered {
    background: $header-logo-centered-bg;
}

.scrolled .header-top-logo-centered {
    background: $header-logo-centered-scrolled-bg;
}

.header-top-logo-centered .header-container {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto minmax(0, 1fr);
}

.header-top-logo-centered .header-left,
.header-top-logo-centered .header-right {
    display: flex;
    align-items: center;
    justify-content: start;
}

.header-button-area {

}

.header-button-area .header-button {
    background: $header-button-bg;
    color: $header-button-color;
    border: $header-button-border;
    border-radius: $header-button-border-radius;
    padding: #{rfs-value($header-button-padding)};
    align-items: center;
    justify-content: center;
    background: transparent;
    display: none;
    margin-left: #{rfs-value($header-button-margin-left)};
    font-size: #{rfs-value($header-button-font-size)};
    line-height: 1;
    text-decoration: none;

    @include media-breakpoint-up($header-logo-centered-breakpoint) {
        display: flex;
    }
}

.header-button-area .header-button:hover {
    background: $header-button-hover-bg;
    color: $header-button-hover-color;
    border: $header-button-hover-border;
}

.scrolled .header-button-area .header-button {
    background: $header-button-scrolled-bg;
    color: $header-button-scrolled-color;
    border: $header-button-scrolled-border;
}

.scrolled .header-button-area .header-button:hover {
    background: $header-button-scrolled-hover-bg;
    color: $header-button-scrolled-hover-color;
    border: $header-button-scrolled-hover-border;
}

//
// Header version: 2, nav version: default
//
[data-header-layout="logo_centered"][data-main-navigation="default"] .desktop-nav-area {
    display: none;

    @include media-breakpoint-up($header-logo-centered-breakpoint) {
        display: flex;
    }
}

[data-header-layout="logo_centered"][data-main-navigation="default"] .hamburger-area {
    @include media-breakpoint-up($header-logo-centered-breakpoint) {
        display: none;
    }
}

[data-header-layout="logo_centered"][data-main-navigation="default"] .header-top-logo-centered .social-area {
    @include media-breakpoint-down($header-logo-centered-breakpoint) {
        display: none;
    }

    @include media-breakpoint-up($header-logo-centered-breakpoint) {
        margin-right: rfs-value(20px);
    }
}

[data-header-layout="logo_centered"][data-main-navigation="default"] .header-top-logo-centered .multilingual-area {
    display: none;

    @include media-breakpoint-up($header-logo-centered-breakpoint) {
        display: flex;
    }
}

//
// Header version: 2, nav version: fullscreen
//
[data-header-layout="logo_centered"][data-main-navigation="fullscreen"] .header-top-logo-centered .desktop-nav-area {
    display: none;

    @include media-breakpoint-up($header-logo-centered-breakpoint) {
        display: flex;
        margin-left: rfs-value(30px);
    }
}

[data-header-layout="logo_centered"][data-main-navigation="fullscreen"] .header-top-logo-centered .social-area {
    display: none;

    @include media-breakpoint-up($header-logo-centered-breakpoint) {
        display: flex;
        margin-right: rfs-value(20px);
    }
}

[data-header-layout="logo_centered"][data-main-navigation="fullscreen"] .header-top-logo-centered .multilingual-area {
    display: none;

    @include media-breakpoint-up($header-logo-centered-breakpoint) {
        display: flex;
    }
}

;@include ---zyywjvlcacm.load-css("sass-embedded-legacy-load-done:12100");