@use "sass:meta" as ---zyywjvlcacm;// Prevent hiding content behind the toolbar
.ccm-toolbar-visible .ccm-page {
    margin-top: 48px;
}

// Move fixed header when user is logged in
.ccm-toolbar-visible .header-top {
    top: 48px !important;
}

.ccm-toolbar-visible .page-image {
    top: 200px !important;
}

// Add more space for small blocks in edit mode
.ccm-edit-mode .social-area,
.ccm-edit-mode .multilingual-area {
    min-width: 50px;
    min-height: 50px;
}

// Set minimum width for hero text area
.ccm-edit-mode .hero-text .ccm-area-block-list {
    min-width: 300px;
}

;@include ---zyywjvlcacm.load-css("sass-embedded-legacy-load-done:12149");