@use "sass:meta" as ---zyywjvlcacm;.logo-area {
    margin-right: auto;
}

.logo {
    width: rfs-value(175px);
    margin: 0 0 rfs-value(15px) 0;
    transition: $transition-base;

    @include media-breakpoint-up(md) {
        width: rfs-value(275px);
        //margin-top: -20px;
        margin: -10px 0 rfs-value(20px) 0;
    }
}

.scrolled .logo {
    @include media-breakpoint-up(md) {
        width: rfs-value(200px);
        margin: 0 0 rfs-value(20px) 0;
    }
}

.logo-link {
}

.logo-image {
}

.logo-link svg {
    width: 100%;
    height: auto;
}

.logo-image-wrapper {
    transition: $transition-base;
    opacity: 1;
}

.scrolled .scrolled-logo-enabled .logo-image-wrapper {
    opacity: 0;
}

.scrolled-logo-image-wrapper {
    transition: $transition-base;
    opacity: 0;
}

.scrolled .scrolled-logo-enabled .scrolled-logo-image-wrapper {
    opacity: 1;
}

;@include ---zyywjvlcacm.load-css("sass-embedded-legacy-load-done:12111");