@use "sass:meta" as ---zyywjvlcacm;.ccm-page .page-list-no-pages,
.ccm-page .page-list-wrapper {
    margin-bottom: rfs-value(40px);

    @include media-breakpoint-up(lg) {
        margin-bottom: rfs-value(50px);
    }
}

.page-list-wrapper .ccm-pagination-wrapper {
    margin-top: rfs-value(30px);

    @include media-breakpoint-up(md) {
        margin-top: rfs-value(50px);
        display: flex;
        justify-content: center;
    }
}

.ccm-page .page-list-item {
    @include rfs(40px, margin-bottom);
    @include rfs(40px, padding-bottom);
}

.ccm-page .page-list-item-title a {
    transition: $transition-base;
    color: inherit;
}

.ccm-page .page-list-item-title a:hover {
    color: $primary;
}

.ccm-page .page-list-item-date {
    color: $gray-500;
}

.ccm-page .page-list-item-read-more .fa-angle-right {
    margin-left: rfs-value(8px);
}

.page-list-item.with-thumbnail {
    @include media-breakpoint-up(sm) {
        display: grid;
        grid-template-columns: rfs-value(150px) 1fr;
        grid-template-rows: 1fr auto auto auto auto 1fr;
        @include rfs(0 40px, grid-gap);
        grid-template-areas:
            'page-list-item-thumbnail .'
            'page-list-item-thumbnail page-list-item-title'
            'page-list-item-thumbnail page-list-item-date'
            'page-list-item-thumbnail page-list-item-description'
            'page-list-item-thumbnail page-list-item-read-more'
            'page-list-item-thumbnail .';
    }

    @include media-breakpoint-up(md) {
        grid-template-columns: rfs-value(300px) 1fr;
    }
}

.with-thumbnail .page-list-item-thumbnail-container {
    @include media-breakpoint-up(sm) {
        grid-area: page-list-item-thumbnail;
    }
}

.page-list-item-title {
    @include media-breakpoint-up(sm) {
        grid-area: page-list-item-title;
    }
}

.page-list-item-date {
    @include media-breakpoint-up(sm) {
        grid-area: page-list-item-date;
    }
}

.page-list-item-description {
    @include media-breakpoint-up(sm) {
        grid-area: page-list-item-description;
    }
}

.page-list-item-read-more-container {
    @include media-breakpoint-up(sm) {
        grid-area: page-list-item-read-more;
    }
}

;@include ---zyywjvlcacm.load-css("sass-embedded-legacy-load-done:12114");