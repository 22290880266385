@use "sass:meta" as ---zyywjvlcacm;@if ($enable-fullscreen-menu-hover-images) {
    html.touch-device .fullscreen-nav .nav-item-image-wrapper {
        display: none !important;
    }

    .fullscreen-nav .nav-item-image-wrapper {
        will-change: opacity;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        width: 100vw;
        height: 100%;
        opacity: 0;
        z-index: -10;
        transition: 0.7s;
        pointer-events: none;
    }

    .fullscreen-nav .nav-item-image {
        transition: 0.7s cubic-bezier(0.65, 0.05, 0.36, 1);
        will-change: transform;
        transform: scale(1.1);
    }

    .fullscreen-nav .nav-link-hover-image:hover + .nav-item-image-wrapper,
    .fullscreen-nav .nav-link-hover-image:hover + .dropdown-toggle + .nav-item-image-wrapper,
    .fullscreen-nav .nav-link-hover-image + .dropdown-toggle:hover + .nav-item-image-wrapper {
        opacity: $fullscreen-menu-hover-image-opacity;
    }

    .fullscreen-nav .nav-link-hover-image:hover + .nav-item-image-wrapper .nav-item-image,
    .fullscreen-nav .nav-link-hover-image:hover + .dropdown-toggle + .nav-item-image-wrapper .nav-item-image,
    .fullscreen-nav .nav-link-hover-image + .dropdown-toggle:hover + .nav-item-image-wrapper .nav-item-image {
        transform: scale(1);
    }
} @else {
    .fullscreen-nav .nav-item-image-wrapper {
        display: none;
    }
}

;@include ---zyywjvlcacm.load-css("sass-embedded-legacy-load-done:12129");