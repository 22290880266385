@use "sass:meta" as ---zyywjvlcacm;.switch-language {
    --switch-language-current-bg: #{$switch-language-current-bg};
    --switch-language-current-color: #{$switch-language-current-color};
    --switch-language-current-border: #{$switch-language-current-border};
    --switch-language-current-font-size: #{rfs-value($switch-language-current-font-size)};
    --switch-language-current-padding: #{rfs-value($switch-language-current-padding)};
    --switch-language-current-border-radius: #{rfs-value($switch-language-current-border-radius)};

    --switch-language-current-hover-bg: #{$switch-language-current-hover-bg};
    --switch-language-current-hover-color: #{$switch-language-current-hover-color};
    --switch-language-current-hover-border: #{$switch-language-current-hover-border};

    --switch-language-current-scrolled-bg: #{$switch-language-current-scrolled-bg};
    --switch-language-current-scrolled-color: #{$switch-language-current-scrolled-color};
    --switch-language-current-scrolled-border: #{$switch-language-current-scrolled-border};

    --switch-language-current-scrolled-hover-bg: #{$switch-language-current-scrolled-hover-bg};
    --switch-language-current-scrolled-hover-color: #{$switch-language-current-scrolled-hover-color};
    --switch-language-current-scrolled-hover-border: #{$switch-language-current-scrolled-hover-border};

    --switch-language-options-border-radius: #{rfs-value($switch-language-options-border-radius)};
    --switch-language-option-bg: #{$switch-language-option-bg};
    --switch-language-option-color: #{$switch-language-option-color};
    --switch-language-option-border-top: #{$switch-language-option-border-top};
    --switch-language-option-padding: #{rfs-value($switch-language-option-padding)};
    --switch-language-option-font-size: #{rfs-value($switch-language-option-font-size)};

    --switch-language-option-hover-bg: #{$switch-language-option-hover-bg};
    --switch-language-option-hover-color: #{$switch-language-option-hover-color};
    --switch-language-option-hover-border-top: #{$switch-language-option-hover-border-top};
}

.switch-language-current {
    background: var(--switch-language-current-bg);
    color: var(--switch-language-current-color);
    border: var(--switch-language-current-border);
    font-size: var(--switch-language-current-font-size);
    padding: var(--switch-language-current-padding);
    border-radius: var(--switch-language-current-border-radius);
    transition: $transition-base;
}

.switch-language.is-open .switch-language-current,
.switch-language-current:hover {
    background: var(--switch-language-current-hover-bg);
    color: var(--switch-language-current-hover-color);
    border-top: var(--switch-language-current-hover-border);
}

.scrolled .switch-language-current {
    background: var(--switch-language-current-scrolled-bg);
    color: var(--switch-language-current-scrolled-color);
    border: var(--switch-language-current-scrolled-border);
}

.scrolled .switch-language.is-open .switch-language-current,
.scrolled .switch-language-current:hover {
    background: var(--switch-language-current-scrolled-hover-bg);
    color: var(--switch-language-current-scrolled-hover-color);
    border: var(--switch-language-current-scrolled-hover-border);
}

.switch-language-current-arrow {
    font-size: rfs-value(13px);
    transition: transform $transition-base-duration;
}

.is-open .switch-language-current-arrow {
    transform: rotate(180deg);
}

.switch-language-options {
    overflow: hidden;
    transition: $transition-base;
    z-index: 1;
    transform: scale(1, 0);
    transform-origin: top;
    opacity: 0;
    border-radius: var(--switch-language-options-border-radius);
}

.switch-language-option {
    background: var(--switch-language-option-bg);
    color: var(--switch-language-option-color);
    border-top: var(--switch-language-option-border-top);
    padding: var(--switch-language-option-padding);
    font-size: var(--switch-language-option-font-size);
    line-height: 1;
    transition: $transition-base;
}

.switch-language-option:hover {
    background: var(--switch-language-option-hover-bg);
    color: var(--switch-language-option-hover-color);
    border-top: var(--switch-language-option-hover-border-top);
    opacity: 1;
}

.switch-language-option:first-child {
    border-top: 0;
}

.is-open .switch-language-options {
    transform: scale(1);
    opacity: 1;
}

;@include ---zyywjvlcacm.load-css("sass-embedded-legacy-load-done:12123");