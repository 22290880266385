@use "sass:meta" as ---zyywjvlcacm;// Common
.header-top {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    z-index: 100;
    transition: background-color .3s;
    box-shadow: 0 0 5px rgba(#000, 0);

    @include media-breakpoint-up($header-logo-centered-breakpoint) {
        left: $header-top-margin;
        top: $header-top-margin;
        right: $header-top-margin;
    }
}

.scrolled .header-top {
    box-shadow: 0 0 5px rgba(#000, 0.3);
}

;@include ---zyywjvlcacm.load-css("sass-embedded-legacy-load-done:12098");