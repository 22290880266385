@use "sass:meta" as ---zyywjvlcacm;.ccm-block-social-links {

}

.ccm-block-social-links a {
    color: $body-color;
}

.ccm-block-social-links a:hover {
    color: $primary;
}

;@include ---zyywjvlcacm.load-css("sass-embedded-legacy-load-done:12113");