@use "sass:meta" as ---zyywjvlcacm;.header-top .ccm-block-social-links a {
    color: $header-social-link-color;
}

.header-top .ccm-block-social-links a:hover {
    color: $header-social-link-hover-color;
}

.scrolled .header-top .ccm-block-social-links a {
    color: $header-social-link-scrolled-color;
}

.scrolled .header-top .ccm-block-social-links a:hover {
    color: $header-social-link-scrolled-hover-color;
}

;@include ---zyywjvlcacm.load-css("sass-embedded-legacy-load-done:12124");