@use "sass:meta" as ---zyywjvlcacm;.spalte_rechts {
    h4 {
        color: $primary;

        &:first-of-type {
            margin-top: 0.55rem;
        }
    }

    p, li {
        font-size: 90%;
    }

    li {
        padding-bottom: 1rem !important;
    }
}
.background-gray-100,
.background-gray-200,
.background-accent-colour {
    border-radius: $border-radius;
    padding: $spacer * 2;
}

.background-gray-100 {
    background: $gray-100;
}

.background-gray-200 {
    background: $gray-200;
}

;@include ---zyywjvlcacm.load-css("sass-embedded-legacy-load-done:12116");