@use "sass:meta" as ---zyywjvlcacm;@use 'sass:map';

/*
 ************************************************************
 Want to change something that's not here?
 You can override every variable from `node_modules/bootstrap/scss/_variables.scss`.
 ************************************************************
*/

// Gray color variables
$white: #fff;
$gray-100: #f4f4f4;
$gray-200: #eee;
$gray-300: #ddd;
$gray-400: #ccc;
$gray-500: #aaa;
$gray-600: #666;
$gray-700: #444;
$gray-800: #333;
$gray-900: #222;

// Theme color variables
$primary: #700808;
$secondary: #a80a0a;
$tertiary: #d1495b;
$quaternary: lighten($primary, 10%);
$quinary: #5a8e13;

// Add a custom color to $theme-colors
$custom-theme-colors: (
        "tertiary": $tertiary,
);

// Spacing
$spacer: 1rem;

// Body
$body-color: $gray-700;
$body-bg: #fff;

// Links
$link-color: $secondary;
$link-decoration: underline;
$link-hover-color: lighten($primary, 20%);
$link-hover-decoration: underline;

// Paragraphs
$paragraph-margin-bottom: 1.5rem;

// Grid breakpoints
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px,
);

// Custom breakpoints
// This will add additional breakpoints but bootstrap classes like "mt-ps-1" will not be generated.
// Add $grid-custom-breakpoints as second argument in "media-breakpoint-xxx" mixins
// Example usage:
// @include media-breakpoint-up(xxxl, $grid-custom-breakpoints) {}
$grid-custom-breakpoints: map-sort-by-values(
                map.merge(
                                $grid-breakpoints,
                                (
                                        ps: 360px,
                                        pm: 414px,
                                        pl: 480px,
                                        xxxl: 1800px,
                                )
                )
) !default;

// Grid containers
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
        xxl: 1440px,
);

// Border variables
$border-color: $gray-300;
$border-radius: 24px;

// Default transition
$transition-base-duration: 0.3s;
$transition-base: all $transition-base-duration;

// Typography
$font-family-base: 'Poppins', -apple-system, BlinkMacSystemFont,
'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$font-family-secondary: 'Covered By Your Grace', -apple-system, BlinkMacSystemFont,
'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$font-size-base: 1rem;

// Font weights
$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;

$line-height-base: 1.5;

// Headings variables
$headings-font-weight: $font-weight-normal;
$headings-color: inherit;
$headings-font-family: $font-family-secondary;
$headings-margin-bottom: $spacer * 1.5;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2.25;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

// Lead text
$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: $font-weight-bold;

// Blockquote
$blockquote-font-size: $font-size-base * 1.1;
$blockquote-margin-y: $spacer * 2.5;

// Marked/highlighted text
$mark-bg: $tertiary;
$mark-color: #fff;
$mark-padding: .1875em .5em;

// Horizontal rule
$hr-margin-y: $spacer * 2;
//$hr-border-color: $border-color;

// Tables
$table-cell-padding-y: 1rem;
$table-cell-padding-x: 1.5rem;

// Buttons
// Visit resources/scss/components/_buttons.scss

// Forms
$input-padding-y: 16px;
$input-placeholder-color: $gray-500;
$input-box-shadow: none;
$input-focus-box-shadow: none;

$form-check-input-width: 1.2em;
$form-group-margin-bottom: 10px;

// Breadcrumb
$breadcrumb-font-size: null;
$breadcrumb-padding-y: 0;
$breadcrumb-padding-x: 0;
$breadcrumb-item-padding-x: .5rem;
$breadcrumb-margin-bottom: 0;
$breadcrumb-bg: $body-bg;
$breadcrumb-divider-color: $gray-500;
$breadcrumb-active-color: $gray-500;
$breadcrumb-divider: quote("/");
$breadcrumb-divider-flipped: $breadcrumb-divider;
$breadcrumb-border-radius: null;
$breadcrumb-color: $body-color;
$breadcrumb-active-color: $body-color;

/*
 ************************************************************
 * Custom blocks and elements
 ************************************************************
*/

// ==========================================================
// Font awesome
// ==========================================================
$font-awesome: 'Font Awesome 5 Free', serif;

// ==========================================================
// Top bar
// ==========================================================
$top-bar-bg: $gray-200;
$top-bar-scrolled-bg: $gray-100;

// ==========================================================
// Header
// ==========================================================
$header-top-margin: 0;

// ==========================================================
// Header ("logo_left" layout)
// ==========================================================
$header-logo-left-bg: rgba(255,255,255,0.9);
$header-logo-left-scrolled-bg: $white;
$header-logo-left-breakpoint: lg;

// ==========================================================
// Header ("logo_centered" layout)
// ==========================================================
$header-logo-centered-bg: transparent;
$header-logo-centered-scrolled-bg: $white;
$header-logo-centered-breakpoint: xl;

// ==========================================================
// Hero
// ==========================================================
$hero-height: 100vh;

// ==========================================================
// Page banner
// ==========================================================
$page-banner-height: 40vh;

// ==========================================================
// Footer
// ==========================================================
$footer-bg: $primary;
$footer-color: rgba(255, 255, 255, 0.7);
$footer-hover-color: rgba(255, 255, 255, 1);

// ==========================================================
// General custom hover effect
// ==========================================================
$custom-hover-overlay-bg: $primary;
$custom-hover-overlay-bg-opacity: .5;
$custom-hover-overlay-icon-color: #fff;

// ==========================================================
// Hamburger
// ==========================================================
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-width: 30px;
$hamburger-layer-width: var(--hamburger-layer-width);
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: $body-color;
$hamburger-layer-scrolled-color: $body-color;
$hamburger-layer-border-radius: 4px;
$hamburger-hover-opacity: 0.7;
$hamburger-active-layer-color: $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: true;
$hamburger-hover-filter: opacity(100%);
$hamburger-active-hover-filter: $hamburger-hover-filter;

// Types
// https://jonsuh.com/hamburgers/
// Change also class in /application/themes/theme/elements/header.php
// If you write spin here, you must also write hamburger--spin in the header.php
$hamburger-types: (spin);

// ==========================================================
// Header social media links
// ==========================================================
$header-social-link-color: $body-color;
$header-social-link-hover-color: $secondary;

$header-social-link-scrolled-color: $body-color;
$header-social-link-scrolled-hover-color: $secondary;

// ==========================================================
// Switch language custom select template
// ==========================================================
$switch-language-border-radius: 3px;

$switch-language-current-bg: transparent;
$switch-language-current-color: #fff;
$switch-language-current-border: 1px solid #fff;

$switch-language-current-hover-bg: #fff;
$switch-language-current-hover-color: $body-color;
$switch-language-current-hover-border: 1px solid #fff;

$switch-language-current-scrolled-bg: #fff;
$switch-language-current-scrolled-color: $body-color;
$switch-language-current-scrolled-border: 1px solid #fff;

$switch-language-current-scrolled-hover-bg: $gray-300;
$switch-language-current-scrolled-hover-color: $body-color;
$switch-language-current-scrolled-hover-border: 1px solid $gray-300;

$switch-language-current-font-size: 14px;
$switch-language-current-padding: 5px 15px;
$switch-language-current-border-radius: $switch-language-border-radius;

$switch-language-options-border-radius: $switch-language-border-radius;
$switch-language-option-bg: $gray-200;
$switch-language-option-color: $body-color;
$switch-language-option-border-top: 1px solid $gray-300;
$switch-language-option-padding: 8px 15px;
$switch-language-option-font-size: 14px;

$switch-language-option-hover-bg: $primary;
$switch-language-option-hover-color: #fff;
$switch-language-option-hover-border-top: 1px solid $primary;

// ==========================================================
// header button
// ==========================================================
$header-button-border-radius: 3px;
$header-button-padding: 15px 28px;
$header-button-font-size: 16px;
$header-button-margin-left: 25px;

$header-button-bg: transparent;
$header-button-color: #fff;
$header-button-border: 1px solid #fff;

$header-button-hover-bg: $primary;
$header-button-hover-color: #fff;
$header-button-hover-border: 1px solid $primary;

// scrolled
$header-button-scrolled-bg: #fff;
$header-button-scrolled-color: $body-color;
$header-button-scrolled-border: 1px solid $border-color;

$header-button-scrolled-hover-bg: $primary;
$header-button-scrolled-hover-color: #fff;
$header-button-scrolled-hover-border: 1px solid $primary;

// ==========================================================
// Main navigation "default" (Desktop menu)
// ==========================================================
$enable-desktop-menu-link-stripe: false;
$desktop-menu-link-stripe-color: #fff;

$desktop-menu-link-spacing-x: 12px;
$desktop-menu-link-color: $body-color;
$desktop-menu-link-hover-color: $secondary;
$desktop-menu-link-active-color: $primary;
$desktop-menu-link-font-size: 18px;
$desktop-menu-link-font-weight: $font-weight-semibold;

$desktop-menu-dropdown-border-width: 0;
$desktop-menu-dropdown-border-color: rgba(#000, 0.175);
$desktop-menu-dropdown-border-radius: 0;
$desktop-menu-dropdown-spacer: 10px;
$desktop-menu-dropdown-padding-y: 0;

$desktop-menu-dropdown-dividers: false;
$desktop-menu-dropdown-divider-bg: rgba(#fff, 0.175);
$desktop-menu-divider-margin-y: 0;
$desktop-menu-divider-margin-x: 0;

$desktop-menu-dropdown-item-padding-x: 20px;
$desktop-menu-dropdown-item-padding-y: 10px;

$desktop-menu-dropdown-bg: $primary;
$desktop-menu-dropdown-font-size: 16px;
$desktop-menu-dropdown-link-color: #fff;
$desktop-menu-dropdown-link-hover-color: #fff;
$desktop-menu-dropdown-link-hover-bg: lighten($primary, 10%);
$desktop-menu-dropdown-link-active-color: #fff;
$desktop-menu-dropdown-link-active-bg: lighten($primary, 10%);

// .scrolled
$desktop-menu-link-stripe-scrolled-color: $primary;

$desktop-menu-link-scrolled-spacing-x: $desktop-menu-link-spacing-x;
$desktop-menu-link-scrolled-color: $body-color;
$desktop-menu-link-scrolled-hover-color: $primary;
$desktop-menu-link-scrolled-active-color: $primary;
$desktop-menu-link-scrolled-font-size: $desktop-menu-link-font-size;
$desktop-menu-link-scrolled-font-weight: $desktop-menu-link-font-weight;

// ==========================================================
// Main navigation "default" (Mobile menu)
// ==========================================================
$mobile-menu-enable-borders: true;

$mobile-menu-padding-x: 24px;
$mobile-menu-padding-y: 24px;

$mobile-menu-color: rgba(#fff, .8);
$mobile-menu-bg: #000;
$mobile-menu-border-color: rgba(#fff, .2);
$mobile-menu-title-line-height: 1.5;

$mobile-menu-header-border: 1px solid $mobile-menu-border-color;
$mobile-menu-btn-close-color: rgba(#fff, .8);

$mobile-menu-nav-link-border: 1px solid $mobile-menu-border-color;
$mobile-menu-nav-link-padding-x: 32px;
$mobile-menu-nav-link-padding-y: 14px;
$mobile-menu-nav-link-font-size: 16px;
$mobile-menu-nav-link-text-transform: uppercase;

$mobile-menu-dropdown-item-padding-x: $mobile-menu-nav-link-padding-x;
$mobile-menu-dropdown-item-padding-y: $mobile-menu-nav-link-padding-y;

$mobile-menu-dropdown-font-size: 16px;
$mobile-menu-dropdown-bg: lighten($mobile-menu-bg, 7%);
$mobile-menu-dropdown-link-color: $mobile-menu-color;
$mobile-menu-dropdown-link-hover-bg: $mobile-menu-dropdown-bg;
$mobile-menu-dropdown-link-hover-color: $mobile-menu-dropdown-link-color;
$mobile-menu-dropdown-link-active-bg: $mobile-menu-dropdown-bg;
$mobile-menu-dropdown-link-active-color: $mobile-menu-dropdown-link-color;
$mobile-menu-dropdown-link-border: 1px solid rgba(#fff, .1);

$mobile-menu-dropdown-link-text-transform: uppercase;

// ==========================================================
// Main navigation "fullscreen" (Fullscreen menu)
// ==========================================================
$fullscreen-menu-animation: 'slide-down'; // slide-down, slide-up, slide-right, slide-left, zoom-in, zoom-out, spin
$fullscreen-menu-animation-opacity: 0; // 0-1
$fullscreen-menu-space-between-columns-x: 80px;
$fullscreen-menu-space-between-columns-y: 50px;
$fullscreen-menu-zindex: 1045;
$fullscreen-menu-horizontal-width: 100vw;
$fullscreen-menu-vertical-height: 100vh;
$fullscreen-menu-spacing-y: 50px;
$fullscreen-menu-padding-x: 0;
$fullscreen-menu-padding-y: 0;

$fullscreen-menu-primary: violet;
$fullscreen-menu-color: #f2f2f2;
$fullscreen-menu-bg: rgba(#222, 1);

$fullscreen-enable-backdrop-filter: false;
$fullscreen-backdrop-filter: blur(10px);

$enable-fullscreen-menu-hover-images: true;
$fullscreen-menu-hover-image-opacity: .2;

$fullscreen-menu-border-width: 0;
$fullscreen-menu-border-color: $border-color;
$fullscreen-menu-box-shadow: 0 .125rem .25rem rgba(#000, .075);

$fullscreen-menu-btn-close-size: 1.5em;
$fullscreen-menu-btn-close-color: $fullscreen-menu-color;
$fullscreen-menu-btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$fullscreen-menu-btn-close-color}'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>");

$fullscreen-menu-link-font-size: 16px;
$fullscreen-menu-link-font-size-large: 26px; // after breakpoint
$fullscreen-menu-nav-link-padding-y: 10px;
$fullscreen-menu-nav-link-padding-y-large: 10px; // after breakpoint
$fullscreen-menu-nav-link-color: #cdcdcd;
$fullscreen-menu-nav-link-hover-color: #cdcdcd;
$fullscreen-menu-nav-link-active-color: $primary;
$fullscreen-menu-nav-link-stripe-color: $fullscreen-menu-primary;
$fullscreen-menu-nav-link-font-weight: $font-weight-normal;

$fullscreen-menu-header-font-size: 34px;
$fullscreen-menu-header-font-weight: $font-weight-semibold;
$fullscreen-menu-header-text-transform: uppercase;
$fullscreen-menu-header-color: $fullscreen-menu-primary;
$fullscreen-menu-list-icon-color: $fullscreen-menu-primary;

$fullscreen-menu-dropdown-toggle-bg: transparent;
$fullscreen-menu-dropdown-toggle-hover-bg: $primary;
$fullscreen-menu-dropdown-toggle-triangle-color: #fff;
$fullscreen-menu-dropdown-toggle-triangle-hover-color: #fff;

// ==========================================================
// Concrete containers
// ==========================================================
$concrete-container-gutter-x-lg: 50px;
$concrete-container-gutter-x-xl: 70px;

// ==========================================================
// Code from Bootstrap 5.3.
// Remove when Bootstrap will be updated.
// ==========================================================
$zindex-levels: (
        n1: -1,
        0: 0,
        1: 1,
        2: 2,
        3: 3
) !default;


;@include ---zyywjvlcacm.load-css("sass-embedded-legacy-load-done:11927");