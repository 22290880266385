@use "sass:meta" as ---zyywjvlcacm;.ccm-page {
    overflow-x: hidden;
}

.container-fluid,
.container {
    //padding-left: rfs-value(30px);
    //padding-right: rfs-value(30px);
}

;@include ---zyywjvlcacm.load-css("sass-embedded-legacy-load-done:12101");