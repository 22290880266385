@use "sass:meta" as ---zyywjvlcacm;.help-page-container {
    margin-bottom: rfs-value(40px);

    @include media-breakpoint-up(md) {
        margin-bottom: rfs-value(80px);
    }
}

.help-page-container .row {
    @include media-breakpoint-up(lg) {
        --bs-gutter-x: #{rfs-value(70px)};
    }
    @include media-breakpoint-up(xl) {
        --bs-gutter-x: #{rfs-value(100px)};
    }
}

;@include ---zyywjvlcacm.load-css("sass-embedded-legacy-load-done:12147");