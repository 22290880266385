@use "sass:meta" as ---zyywjvlcacm;@use 'sass:math';

:root {
    --hamburger-layer-width: #{$hamburger-width};
    //--concrete-container-gutter-x: #{$grid-gutter-width * 0.5};
    --side-space: 30px;

    @include media-breakpoint-up(lg) {
        --side-space: 50px;
        --concrete-container-gutter-x: #{$concrete-container-gutter-x-lg};
    }

    @include media-breakpoint-up(xl) {
        --concrete-container-gutter-x: #{$concrete-container-gutter-x-xl};
    }

    @include media-breakpoint-up(xxxl, $grid-custom-breakpoints) {
        --side-space: #{$grid-gutter-width * 0.5};
    }
}

;@include ---zyywjvlcacm.load-css("sass-embedded-legacy-load-done:12086");