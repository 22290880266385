@use "sass:meta" as ---zyywjvlcacm;// Common

.header-top {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.header-top-logo-left {
    background: $header-logo-left-bg;
}

.scrolled .header-top-logo-left {
    background: $header-logo-left-scrolled-bg;
}

.header-top-logo-left .top-bar-wrapper {
    display: none;
    transition: $transition-base;
    background: $top-bar-bg;
    //padding-top: rfs-value(4px);
    padding-bottom: rfs-value(4px);

    @include media-breakpoint-up($header-logo-left-breakpoint) {
        display: flex;
        align-items: center;
    }
}

.scrolled .header-top-logo-left .top-bar-wrapper {
    background: $top-bar-scrolled-bg;
}

.header-top-logo-left .top-bar {
    transition: $transition-base;
    //transform: translateY(rfs-value(10px));
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    z-index: 3;
}

.scrolled .header-top-logo-left .top-bar {
    //transform: translateY(0);

}

.header-top-logo-left .top-bar .address {
    p {
        font-size: $font-size-base * .75;
        margin-bottom: 0 !important;
    }
}

.header-top-logo-left .header-container {
    display: flex;
    align-items: center;
}

//
// Header version: 1, nav version: default
//
[data-header-layout="logo_left"][data-main-navigation="default"] .desktop-nav-area {
    display: none;

    @include media-breakpoint-up($header-logo-left-breakpoint) {
        display: block;
    }
}

[data-header-layout="logo_left"][data-main-navigation="default"] .hamburger-area {
    display: flex;

    @include media-breakpoint-up($header-logo-left-breakpoint) {
        display: none;
    }
}

//
// Header version: 1, nav version: fullscreen
//
[data-header-layout="logo_left"][data-main-navigation="fullscreen"] .desktop-nav-area {
    display: none;
}

[data-header-layout="logo_left"][data-main-navigation="fullscreen"] .hamburger-area {
    display: flex;
}

;@include ---zyywjvlcacm.load-css("sass-embedded-legacy-load-done:12099");